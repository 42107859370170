import { FC, ReactElement } from 'react';
import { AvailableDynamicEnvVariables, useDynamicEnvVariables } from '@pba-shared-libs/core';

export const SidebarLogo: FC = (): ReactElement => {
  const { getDynamicEnvVariableValue } = useDynamicEnvVariables();
  const routerBasePath = getDynamicEnvVariableValue(AvailableDynamicEnvVariables.reactRouterBasePathPrefix) || '';

  return (
    <div className="flex h-14 w-14 items-center justify-center">
      <img src={`${routerBasePath}/images/yettel_bank.svg`} alt="yettel bank logo" />
    </div>
  );
};
