import { Dispatch, FC, memo, ReactElement, SetStateAction, SyntheticEvent, useMemo } from 'react';
import { Button, Tooltip } from '@nextui-org/react';
import { useLocation } from 'react-router-dom';
import { Nullable } from '@pba-shared-libs/core';
import { HeroIcon } from '@pba-shared-libs/ui';
import { IAppModule } from '@/types';
import { SidebarNavItem } from './SidebarNavItem';

interface SidebarItemProps {
  item: IAppModule;
  solidIcon?: boolean;
  onClick?: (e: SyntheticEvent) => void;
  onHoverItem?: (item: IAppModule) => void;
  onLeaveItem?: Dispatch<SetStateAction<Nullable<IAppModule>>>;
}

export const SidebarItem: FC<SidebarItemProps> = memo(
  ({ item, solidIcon, onClick, onHoverItem, onLeaveItem }): ReactElement => {
    const { icon, name, uri = '' } = item;
    const { pathname } = useLocation();

    const isActive = useMemo(() => uri === pathname, [pathname, uri]);

    const handleClick = (e: SyntheticEvent) => {
      if (onClick) onClick(e);
    };

    const handleMouseEnter = () => {
      if (onHoverItem) {
        if (item?.items?.length) onHoverItem(item);
        else onHoverItem(null);
      }
    };

    const handleMouseLeave = () => {
      if (onLeaveItem) onLeaveItem(null);
    };

    return (
      <li className="m-0">
        <Tooltip content={name} placement="right" offset={8}>
          <Button
            to={uri}
            color={isActive ? 'primary' : 'default'}
            variant="light"
            isIconOnly
            as={SidebarNavItem}
            onClick={handleClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <HeroIcon name={icon} solid={solidIcon} />
          </Button>
        </Tooltip>
      </li>
    );
  },
);
SidebarItem.displayName = 'SidebarItem';
