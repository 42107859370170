import { Nullable } from '@pba-shared-libs/core';
import { ISidebarNavItemWithOnClick } from '@/components';
import { IAppModule } from '@/types';
import { SidebarItem } from './SidebarItem';

interface SidebarNavListProps {
  items: ISidebarNavItemWithOnClick[];
  onHoverItem?: (item: IAppModule) => void;
  onLeaveItem?: React.Dispatch<React.SetStateAction<Nullable<IAppModule>>>;
}

export const SidebarNavList: React.FC<SidebarNavListProps> = ({
  items,
  onHoverItem,
  onLeaveItem,
  ...restProps
}): React.ReactElement => (
  <ul className="flex flex-col gap-4 items-center" {...restProps}>
    {items.map(item => (
      <SidebarItem
        key={item.id}
        item={item}
        onHoverItem={onHoverItem}
        onLeaveItem={onLeaveItem}
        onClick={item?.onClick || undefined}
      />
    ))}
  </ul>
);
