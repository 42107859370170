import { useEffect } from 'react';
import { getAccessToken } from '@pba-shared-libs/core';
// import { getAccessToken } from '@/core';

// TODO improve sharing keys with MFEs
const MfeListeners = {
  user: {
    requireAccessToken: {
      key: 'REQUIRE_ACCESS_TOKEN',
      listener: () => {
        // eslint-disable-next-line no-console
        console.log('==>> appShell [REQUIRE_ACCESS_TOKEN]');
        const token = getAccessToken();
        window.dispatchEvent(new CustomEvent('RECEIVE_ACCESS_TOKEN', { detail: { token } }));
      },
    },
    // getIdentity: {
    //   key: 'GET_USER_IDENTITY',
    //   listener: () => sessionStorage.getItem(SessionStorageKeys.user.identity),
    // },
  },
};

export const useMfeListeners = () => {
  useEffect(() => {
    window.addEventListener(MfeListeners.user.requireAccessToken.key, MfeListeners.user.requireAccessToken.listener);
    // window.addEventListener(MfeListeners.user.getIdentity.key, MfeListeners.user.getIdentity.listener);

    return () => {
      window.removeEventListener(
        MfeListeners.user.requireAccessToken.key,
        MfeListeners.user.requireAccessToken.listener,
      );
      // window.removeEventListener(MfeListeners.user.getIdentity.key, MfeListeners.user.getIdentity.listener);
    };
  }, []);
};
