import { AuthClientEvent } from '@react-keycloak/core';
import { removeKeycloakTokens } from './tokenHandler';

const onLogout = () => removeKeycloakTokens();

export const keycloakEventHandler = (eventType: AuthClientEvent): void => {
  switch (eventType) {
    case 'onAuthLogout':
      onLogout();
      break;

    case 'onReady':
    case 'onAuthSuccess':
    case 'onAuthError':
    case 'onInitError':
    case 'onAuthRefreshError':
    case 'onAuthRefreshSuccess':
    case 'onTokenExpired':
    default:
  }
};
