import { useEffect, useRef, useCallback } from 'react';
import { useKeycloak } from '@react-keycloak/web';

const TOKEN_MIN_VALIDITY_IN_SECONDS = 10;
const TOKEN_VALIDITY_THRESHOLD_IN_SECONDS = 5;

export const useAuthCheck = () => {
  const { keycloak } = useKeycloak();
  const timeoutRef = useRef<undefined | number>(undefined);

  const updateToken = useCallback(() => {
    const tokenValidityDefault =
      (Number((keycloak.tokenParsed?.exp ?? 0) - (keycloak.tokenParsed?.iat ?? 0)) as number) ?? 60 * 5;
    timeoutRef.current = setTimeout(
      () => {
        keycloak.updateToken(TOKEN_MIN_VALIDITY_IN_SECONDS);
        updateToken();
      },
      (tokenValidityDefault - TOKEN_VALIDITY_THRESHOLD_IN_SECONDS) * 1_000,
    );
  }, [keycloak]);

  useEffect(() => {
    if (keycloak.tokenParsed && keycloak.tokenParsed.exp && keycloak.tokenParsed.iat) updateToken();
  }, [keycloak.tokenParsed, keycloak.tokenParsed?.exp, updateToken]);
};
