import { FC, PropsWithChildren, ReactElement } from 'react';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { AuthClientInitOptions } from '@react-keycloak/core/lib/types';
import Keycloak from 'keycloak-js';
import { keycloakEventHandler } from './eventHandler';
import { keycloakTokenHandler } from './tokenHandler';

const baseOptions: AuthClientInitOptions = {
  onLoad: 'login-required',
  checkLoginIframe: false,
};

interface AuthenticationKeycloakProviderProps extends PropsWithChildren {
  instance: Keycloak;
}

export const AuthenticationKeycloakProvider: FC<AuthenticationKeycloakProviderProps> = ({
  instance,
  children,
}): ReactElement => (
  <ReactKeycloakProvider
    authClient={instance}
    initOptions={baseOptions}
    onEvent={keycloakEventHandler}
    onTokens={keycloakTokenHandler}
  >
    {children}
  </ReactKeycloakProvider>
);
