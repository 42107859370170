import { useCallback } from 'react';
import { calculateNextDelay, getRetryDelay } from '@/core';
import { DEFAULT_FETCH_TIMEOUT, DEFAULT_INITIAL_RETRY_DELAY, DEFAULT_MAX_RETRIES } from './constants';
import { delayBeforeNextAttempt } from './delayBeforeNextAttempt';

interface LoadComponentOptions {
  maxRetries?: number;
  initialRetryDelay?: number;
  fetchTimeout?: number;
}

// FIXME
const mfeAppMap = {};

export const useRetriedComponentLoader = (defaultOptions: LoadComponentOptions = {}) => {
  const {
    maxRetries: defaultMaxRetries = DEFAULT_MAX_RETRIES,
    initialRetryDelay: defaultInitialRetryDelay = DEFAULT_INITIAL_RETRY_DELAY,
    fetchTimeout: defaultFetchTimeout = DEFAULT_FETCH_TIMEOUT,
  } = defaultOptions;

  const loadComponent = useCallback(
    async (
      mfeAppName: keyof Record<string, () => Promise<{ default: React.ComponentType<any> }>>,
      options: LoadComponentOptions = {},
    ): Promise<React.ComponentType | null> => {
      const {
        maxRetries = defaultMaxRetries,
        initialRetryDelay = defaultInitialRetryDelay,
        fetchTimeout = defaultFetchTimeout,
      } = options;

      if (!mfeAppMap[mfeAppName]) {
        console.error(`Federated application '${mfeAppName}' not found`);
        return null;
      }

      let retries = maxRetries;
      let delay = getRetryDelay(initialRetryDelay);

      while (retries > 0) {
        const abortController = new AbortController();
        const fetchTimeoutId = setTimeout(() => abortController.abort(), fetchTimeout);

        try {
          const { default: loadedComponent } = await mfeAppMap[mfeAppName]();
          return loadedComponent;
        } catch (err: unknown) {
          let errMessage = 'Unknown error';
          if (typeof err === 'string') errMessage = err;
          else if (err instanceof Error) errMessage = err.message;
          console.error(`==>> Error occurred while fetching (${errMessage}). Retrying...`);

          retries--;
          delay = calculateNextDelay(delay);
          await delayBeforeNextAttempt(delay.current);
        } finally {
          abortController.abort();
          clearTimeout(fetchTimeoutId);
        }
      }

      return null;
    },
    [defaultFetchTimeout, defaultInitialRetryDelay, defaultMaxRetries],
  );

  return { loadComponent };
};
