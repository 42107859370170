import { ReactElement, Suspense } from 'react';
import { createBrowserRouter, RouterProvider, RouteObject } from 'react-router-dom';
import { DefaultSuspenseFallback, LoaderGlobal } from '@pba-shared-libs/ui';
import { LayoutDefault } from './Layouts';
import {
  FederatedMfeBlogPosts,
  FederatedMfeDashboard,
  FederatedMfeInbox,
  FederatedMfePromos,
  FederatedMfeSettings,
} from './core';
import { NotFoundPage } from '@/pages';

function App(): ReactElement {
  const routes: RouteObject[] = [
    {
      path: '/',
      element: <LayoutDefault />,
      children: [
        { index: true, element: <FederatedMfeDashboard /> },
        { path: 'blogposts/*', element: <FederatedMfeBlogPosts /> },
        { path: 'inbox/*', element: <FederatedMfeInbox /> },
        { path: 'promos/*', element: <FederatedMfePromos /> },
        { path: 'settings/*', element: <FederatedMfeSettings /> },
      ],
    },
    { path: '*', element: <NotFoundPage /> },
  ];
  const router = createBrowserRouter(routes);

  return (
    <>
      <LoaderGlobal />
      <Suspense fallback={<DefaultSuspenseFallback />}>
        <RouterProvider router={router} />
      </Suspense>
    </>
  );
}

export default App;
