import { forwardRef } from 'react';
import { NavLink as RouterLink, NavLinkProps as RouterLinkProps } from 'react-router-dom';
import { ButtonProps as NuiButtonProps } from '@pba-shared-libs/ui';

interface SidebarNavItemProps extends Pick<RouterLinkProps, 'to' | 'children'>, Pick<NuiButtonProps, 'href'> {}

export const SidebarNavItem = forwardRef<HTMLAnchorElement, SidebarNavItemProps>(
  ({ to, href, children, ...restProps }, ref) => (
    <RouterLink to={to || href} ref={ref} {...restProps}>
      {children}
    </RouterLink>
  ),
);
SidebarNavItem.displayName = 'SidebarNavItem';
