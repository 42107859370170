import { AuthClient } from '@react-keycloak/core';

export const AuthConstants = {
  accessToken: 'accessToken',
  impersonation: 'impersonation',
  logout: 'logout',
};

export const keycloakTokenHandler = (tokens: Pick<AuthClient, 'idToken' | 'refreshToken' | 'token'>) =>
  sessionStorage.setItem(AuthConstants.accessToken, tokens.token ?? '');

export const getAccessToken = () => {
  const value = sessionStorage.getItem(AuthConstants.accessToken);
  if (value === 'null') return null;

  return value;
};

export const getRefreshToken = () => {
  const value = sessionStorage.getItem(AuthConstants.impersonation);
  if (value === 'null') return null;

  return value;
};

export const setAccessToken = (accessToken?: string) =>
  sessionStorage.setItem(AuthConstants.accessToken, accessToken ?? 'null');

export const setRefreshToken = (refreshToken?: string) =>
  sessionStorage.setItem(AuthConstants.impersonation, refreshToken ?? 'null');

export const removeKeycloakTokens = () => {
  sessionStorage.removeItem(AuthConstants.accessToken);
  sessionStorage.removeItem(AuthConstants.impersonation);
};
