import { Listbox, ListboxItem } from '@nextui-org/react';
import { IAppSubModule } from '@/types';
import { SidebarNavItem } from './SidebarNavItem';

interface SidebarSubItemListProps {
  items: IAppSubModule[];
  ariaLabel: string;
}

export const SidebarSubItemList: React.FC<SidebarSubItemListProps> = ({
  items = [],
  ariaLabel,
}): React.ReactElement => (
  <Listbox aria-label={ariaLabel}>
    {items.map(item => (
      <ListboxItem key={item.id} as={SidebarNavItem} href={item.uri} to={item.uri} className="py-3.5 px-6">
        {item.name}
      </ListboxItem>
    ))}
  </Listbox>
);
