import { FC, ReactElement } from 'react';
import { Outlet } from 'react-router-dom';
import {
  NotificationCentreLazy,
  NotificationCentreOtherMessage,
  NotificationCentreSuccessMessage,
  defaultFilterSuccessMessages,
} from '@pba-shared-libs/ui';
import { Sidebar } from '@/components';
import { useNotificationCentreStore } from '@/core';

export const LayoutDefault: FC = (): ReactElement => {
  const { hasAnyMessage, getMessages, clearAll, isCentreOpened, toggleCentreOpened, deleteMessage } =
    useNotificationCentreStore();

  return (
    <div className="relative flex h-screen">
      <Sidebar />

      <div className="flex flex-1 w-full h-full p-16 ml-[80px]">
        <Outlet />
      </div>

      <NotificationCentreLazy
        isOpened={isCentreOpened}
        hasAnyMessage={hasAnyMessage()}
        hasAnySuccessMessage={hasAnyMessage(defaultFilterSuccessMessages)}
        successMessages={getMessages(defaultFilterSuccessMessages) as NotificationCentreSuccessMessage[]}
        otherMessages={getMessages() as NotificationCentreOtherMessage[]}
        onClickDeleteMessage={deleteMessage}
        onClickToggleOpened={toggleCentreOpened}
        onClickClearAll={clearAll}
      />
    </div>
  );
};
