import { useMemo, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { Nullable } from '@pba-shared-libs/core';
import { IAppModule } from '@/types';
import { SidebarSubItemList, SidebarLogo } from './components';
import { SidebarNavList } from './components/SidebarNavList';

export interface ISidebarNavItemWithOnClick extends IAppModule {
  onClick?: (e: React.SyntheticEvent) => void;
}

export const Sidebar: React.FC = (): React.ReactElement => {
  const [hoveredItem, setHoveredItem] = useState<Nullable<IAppModule>>(null);
  const [animationClass, setAnimationClass] = useState<string>('');
  const {
    keycloak: { authenticated, logout },
  } = useKeycloak();

  const handleMouseEnter = (item: IAppModule) => {
    setHoveredItem(item);
    setAnimationClass('animate-slide-in-left');
  };

  const handleMouseLeave = () => {
    setAnimationClass('animate-slide-out-left');
    setTimeout(() => {
      setHoveredItem(null);
    }, 500);
  };

  const data: { items: ISidebarNavItemWithOnClick[] } = {
    items: [
      {
        id: 1,
        icon: 'RectangleGroupIcon',
        name: 'Dashboard',
        uri: '/',
      },
      {
        id: 2,
        icon: 'DevicePhoneMobileIcon',
        name: 'Blog',
        uri: '/blogposts',
      },
      {
        id: 3,
        icon: 'EnvelopeIcon',
        name: 'Inbox',
        uri: '/inbox',
      },
      {
        id: 4,
        icon: 'ReceiptPercentIcon',
        name: 'Promos',
        uri: '/promos',
      },
      {
        id: 5,
        icon: 'Cog6ToothIcon',
        name: 'Settings',
        uri: '/settings/about',
        items: [{ id: 6, name: 'About the app', uri: '/settings/about' }],
      },
    ],
  };

  const userLinks = useMemo(
    (): ISidebarNavItemWithOnClick[] =>
      authenticated
        ? [
            { id: 100, icon: 'UserIcon', name: 'Profile', uri: '/profile' },
            {
              id: 101,
              icon: 'ArrowRightStartOnRectangleIcon',
              name: 'Logout',
              uri: '/logout',
              onClick: (e: React.SyntheticEvent) => {
                e.preventDefault();
                logout();
              },
            },
          ]
        : [{ id: 200, icon: 'ArrowRightOnRectangleIcon', name: 'Login', uri: '/login' }],
    [authenticated, logout],
  );

  return (
    <div className="relative flex" onMouseLeave={handleMouseLeave}>
      <aside
        className="fixed w-[80px] h-screen flex flex-col justify-between px-3 py-4 z-50 bg-zinc-900"
        aria-label="Sidebar Navigation"
      >
        <nav>
          <SidebarLogo />
          <SidebarNavList items={data?.items || []} aria-label="Main Navigation" onHoverItem={handleMouseEnter} />
        </nav>

        <SidebarNavList items={userLinks} aria-label="User Authentication" />
      </aside>

      {hoveredItem && (
        <div
          className={`fixed z-40 w-full h-full left-[80px] top-0 p-3 rounded-tr-xl rounded-br-xl border-l-1 border-l-zinc-700 max-w-[270px] bg-zinc-900 ${animationClass}`}
          aria-expanded="true"
          aria-label={`Submenu for ${hoveredItem.name}`}
        >
          <p className="text-blue-500 py-6 px-4 border-b-1 border-b-zinc-700">{hoveredItem.name}</p>
          <SidebarSubItemList items={hoveredItem?.items || []} ariaLabel={`Submenu items for ${hoveredItem.name}`} />
        </div>
      )}
    </div>
  );
};
