import { ComponentType, lazy, LazyExoticComponent } from 'react';
// @ts-ignore
import { __federation_method_getRemote, __federation_method_setRemote } from '__federation__';

export const createFederatedAppComponent = (
  url: string,
  scope: string,
  module: string,
): LazyExoticComponent<ComponentType<any>> =>
  lazy(() => {
    __federation_method_setRemote(scope, { url: () => Promise.resolve(url), format: 'esm', from: 'vite' });

    return __federation_method_getRemote(scope, module);
  });
