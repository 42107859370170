import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { NextUIProvider } from '@nextui-org/react';
import Keycloak from 'keycloak-js';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { DynamicEnvVariablesProvider } from '@pba-shared-libs/core';
import { AuthenticationKeycloakProvider } from '@/core';
import App from './App';
import '@pba-shared-libs/ui/style.css';

const queryClient = new QueryClient();

const keycloakInstance = new Keycloak({
  url: import.meta.env.VITE_KEYCLOAK_URL,
  realm: import.meta.env.VITE_KEYCLOAK_REALM,
  clientId: import.meta.env.VITE_KEYCLOAK_CLIENT_ID,
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <AuthenticationKeycloakProvider instance={keycloakInstance}>
    <StrictMode>
      <QueryClientProvider client={queryClient}>
        <DynamicEnvVariablesProvider>
          <NextUIProvider>
            <App />
          </NextUIProvider>
        </DynamicEnvVariablesProvider>
      </QueryClientProvider>
    </StrictMode>
  </AuthenticationKeycloakProvider>,
);
